.hero {
  background-image: url("../../../assets/bgimage.jpg");
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taglineBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -40px;
}

.taglineBox__rotatedText {
  transform: rotate(-90deg);
}

.taglineBox__rotatedText > p {
  color: #780000;
  font-family: "Audiowide", cursive;
  font-size: 76px;
  font-style: normal;
  font-weight: 400;
}

.taglineBox__remainingText {
  display: flex;
  flex-direction: column;
  margin-left: -170px;
}

.taglineBox__remainingText > p {
  margin: 30px;
  color: #780000;
  font-family: "Audiowide", cursive;
  font-size: 80px;
  font-style: normal;
}

.trynowBtn {
  margin-right: 80px;
  margin-top: 350px;
}

.trynowBtn__button {
  cursor: pointer;
  font-family: "Ubuntu", sans-serif;
  color: white;
  padding: 18px 73px;
  /* padding: 25px 40px; new padding */ 
  border-radius: 4px;
  background-color: #000000;
  transition: all ease-in-out 0.1s;
}

.trynowBtn__button:hover {
  background-color: #382f2fc8;
  transform: scale(1.05);
}

.trynowBtn__button:active {
  background-color: #524c4cc8;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    justify-content: center;
    padding: 40px;
  }

  .taglineBox {
    margin-left: 0;
    margin-bottom: 30px;
  }

  .taglineBox__rotatedText > p {
    font-size: 36px;
  }

  .taglineBox__remainingText {
    margin-left: 0;
  }

  .taglineBox__remainingText > p {
    margin: 20px;
    font-size: 48px;
  }

  .trynowBtn {
    margin: 50px auto 0;
  }
}

@media (max-width: 480px) {
  .taglineBox__rotatedText > p {
    font-size: 24px;
  }

  .taglineBox__remainingText > p {
    margin: 10px;
    font-size: 36px;
  }

  .trynowBtn__button {
    padding: 14px 54px;
  }
}

