.overview {
  background-image: url("../../../assets/overviewbg.jpg");
  height: 60vh;
  background-size: cover;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.overview__product {
  border-radius: 24px;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px 14px;
}

.overview__product__box1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.overview__product__box1 > img,
.overview__product__box2__images > div > img {
  border-radius: 12px;
}

.overview__product__box1 > p,
.overview__product__box2 > p {
  padding: 10px;
  background-color: black;
  border-radius: 7px;
  color: white;
  font-family: "Ubuntu", sans-serif;
}

.overview__product__box2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.overview__product__box2__images {
  display: flex;
  gap: 5px;
  justify-content: space-evenly;
  align-items: center;
}

.tagline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 292px;
}

.tagline__header {
  color: #d6a88c;
  font-family: Audiowide;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
}

.tagline__content {
  color: #ebd5c8;
  font-family: monospace;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

