.pricing {
  background-color: #1d0000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pricing__title {
  color: #d6a88c;
  font-family: Audiowide;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 90px;
  margin-top: 133px;
}

.pricing__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Use this if there are 2 pricing plans */
  /* grid-template-columns: repeat(1, 1fr); */
  gap: 133px;
  margin-bottom: 100px;
}

.pricing__container__box {
  background-color: #ebd5c8;
  display: flex;
  width: 323px;
  height: 424px;
  border-radius: 22px;
  padding: 36px;
  flex-direction: column;
  align-items: center;
  gap: 36px;
}

.pricing__container__box__head1 {
  color: #383838;
  text-align: center;
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.pricing__container__box__head2 {
  color: #000;
  text-align: center;
  font-family: Audiowide;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
}

.pricing__container__box__perks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.pricing__container__box__perks > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.pricing__container__box__perks > div > p {
  color: rgba(0, 0, 0, 0.9);
  font-family: Ubuntu;
  font-size: 20px;
}

.pricing__container__box__perks__link{
  color: rgba(0, 0, 0, 0.9);
  font-family: Ubuntu;
  font-size: 15px;
}

.pricing__container__box__btn {
  text-align: center;
  width: 100%;
  padding: 20px;
  border-radius: 9px;
  cursor: pointer;
  transition: all ease-in-out 0.1s;
}

.pricing__container__box__btn:hover {
  transform: scale(1.03);
}

.pricing__container__box__btn--free {
  background-color: #fffdfd;
  font-size: 18px;
  font-family: Ubuntu;
}

.pricing__container__box__btn--free:active {
  background-color: #b2afaf;
}

.pricing__container__box__btn--pro {
  font-size: 18px;
  font-family: Ubuntu;
  background-color: #ff3131;
}

.pricing__container__box__btn--pro:active {
  background-color: #d37474;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .pricing__container {
    grid-template-columns: 1fr;
    gap: 60px;
  }

  .pricing__container__box {
    width: 100%;
    max-width: 500px;
    height: auto;
    padding: 24px;
  }

  .pricing__container__box__head1,
  .pricing__container__box__head2 {
    font-size: 24px;
    line-height: 36px;
  }

  .pricing__container__box__perks > div > p {
    font-size: 16px;
  }

  .pricing__container__box__btn {
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .pricing__title {
    margin-bottom: 60px;
    margin-top: 80px;
    font-size: 28px;
    line-height: 40px;
  }

  .pricing__container {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .pricing__container__box {
    padding: 20px;
  }

  .pricing__container__box__head1,
  .pricing__container__box__head2 {
    font-size: 20px;
    line-height: 32px;
  }

  .pricing__container__box__perks > div > p {
    font-size: 14px;
  }

  .pricing__container__box__btn {
    padding: 10px;
  }
}
