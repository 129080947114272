/* Default styles */
.about {
  background-image: url("../../../assets/aboutbg.jpg");
  background-size: cover;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.about__content {
  margin-top: 114px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 420px;
}

.about__content > header {
  color: #d6a88c;
  font-family: Audiowide;
  font-size: 32px;
  font-weight: 400;
  line-height: 48px;
}

.about_content_features {
  margin-top: 12px;
}

.about_content_features > header {
  color: #ebd5c8;
  font-family: Ubuntu;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
}

.about_content_features > p {
  color: #ebd5c8;
  font-family: monospace;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
}

.about__images {
  margin-top: 144px;
  display: grid;
  gap: 23px;
  grid-template-columns: repeat(2, 1fr);
}

.about__images__imagecol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about__images__imagecol > p {
  text-align: center;
  padding: 5px;
  background-color: black;
  border-radius: 7px;
  color: white;
  font-family: "Ubuntu", sans-serif;
}

.about__images__imagecol__picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about__images__imagecol__picture > p {
  position: relative;
  top: -20px;
  color: #ebd5c8;
  padding: 5px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.199);
}

.about__images__imagecol__picture > img {
  border-radius: 6px;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .about {
    flex-direction: column;
  }

  .about__content {
    width: 90%;
    text-align: center;
  }

  .about__images {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 60px;
  }

  .about__images__imagecol {
    align-items: flex-start;
  }

  .about__images__imagecol__picture > p {
    top: 0;
  }
}

@media (max-width: 480px) {
  .about__content {
    margin-top: 60px;
  }

  .about_content_features > header {
    font-size: 20px;
    line-height: 30px;
  }

  .about_content_features > p {
    font-size: 16px;
    line-height: 26px;
  }

  .about__images {
    margin-top: 40px;
  }

  .about__images__imagecol > p {
    font-size: 14px;
  }

  .about__images__imagecol__picture > p {
    font-size: 12px;
  }

  .about__images__imagecol__picture > img {
    border-radius: 4px;
  }
}

