/* Default styles */
.navbar {
  background-color: rgba(254, 234, 234, 0.682);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
}

.navbar__logo {
  padding: 6px;
}

.navbar__logo > img {
  width: 300px;
  border-radius: 5px;
}

.navbar__navItems {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 29px;
  margin-right: 22px;
}

.navbar__navItems > p {
  cursor: pointer;
  font-family: "Ubuntu", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  transition: all ease-in-out 0.1s;
  padding: 2px;
}

.navbar__navItems > p:hover {
  transform: scale(1.05);
  background-color: rgba(254, 234, 234, 0.896);
  border-radius: 12px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .navbar__navItems {
    gap: 15px;
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 10px;
  }

  .navbar__navItems {
    flex-direction: column;
    gap: 10px;
    margin-right: 0;
  }

  .navbar__navItems > p {
    text-align: center;
    width: 100%;
    padding: 6px;
  }
}

@media (max-width: 480px) {
  .navbar__logo > img {
    width: 28px;
  }

  .navbar__navItems {
    gap: 8px;
  }

  .navbar__navItems > p {
    font-size: 16px;
  }
}

