.footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #1d0000;
  padding: 32px;
  border-top: 1px solid #8C5432;
}

.footer__copyright {
  color: #e0beaa;
  font-family: Ubuntu;
  font-size: 16px;
}

.footer_link {
  display: flex;
  gap: 33px;
  color: #e0beaa;
  font-family: Ubuntu;
  font-size: 16px;
}

.footer__policy {
  display: flex;
  gap: 33px;
  color: #e0beaa;
  font-family: Ubuntu;
  font-size: 16px;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.footer__socials > svg {
  cursor: pointer;
  transition: all ease-in-out 0.1s;
}

.footer__socials > svg:hover {
  transform: scale(1.06);
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    padding: 20px;
    text-align: center;
  }

  .footer__policy {
    margin-top: 20px;
    gap: 20px;
  }

  .footer__socials {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .footer__policy {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer__policy > p {
    margin-bottom: 10px;
  }

  .footer__socials {
    margin-top: 10px;
  }
}

